<template>
  <v-dialog v-model="localDialog" persistent max-width="310">
    <v-card>
      <v-card-title class="success pl-3 white--text">{{ $t("app.confirmBuy") }}</v-card-title>
      <v-card-text class="body-1 pa-3"
        >{{ $tc("app.confirmBuyText", points, { count: points }) }}
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text outlined @click.native="cancelDialog()">
          <v-icon size="20" left>mdi-cancel</v-icon>
          {{ $t("app.cancel") }}
        </v-btn>
        <v-btn color="success" outlined @click.native="localDialog = true">
          <v-icon size="20" left>mdi-cart</v-icon>{{ $t("app.confirm") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: {
    dialog: {
      type: Boolean,
      default: false
    },
    points: {
      type: Number,
      default: 0
    }
  },
  computed: {
    localDialog: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit("buy-dialog-update", value);
      }
    }
  },
  methods: {
    cancelDialog() {
      this.$emit("buy-dialog-cancel", true);
    }
  }
};
</script>
